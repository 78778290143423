/**
* ruoyi参数处理
* @param {*} params  参数
*/
export function tansParams(params) {
    let result = ''
    for (const propName of Object.keys(params)) {
        const value = params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof (value) !== "undefined") {
            if (typeof value === 'object') {
                for (const key of Object.keys(value)) {
                    if (value[key] !== null && typeof (value[key]) !== 'undefined') {
                        let params = propName + '[' + key + ']';
                        var subPart = encodeURIComponent(params) + "=";
                        result += subPart + encodeURIComponent(value[key]) + "&";
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + "&";
            }
        }
    }
    return result
}


/**
 * 返回项目状态文字信息
 * 
 */
export function pjStateText(t) {
    t = t + ''
    switch (t) {
        case '1':
            return "立项审批中"
        case "2":
            return "立项完成"
        case "3":
            return "签订合同"
        case "4":
            return "实施中"
        case "5":
            return "实施完成"
        case "6":
            return "验收通过"
        case "7":
            return "收款完成"
        case "8":
            return "付款完成"
        case "9":
            return "归档完成"
        case "10":
            return "项目完成"
        case "11":
            return "项目终止"
        case "12":
            return "撤销"
        default:
            return '未知状态'
    }
}

/**
 * 返回审核类型文字信息
 * 
 */

export function reviewedText(t) {
    t = t + ''
    switch (t) {
        case "1":
            return "项目"
        case "2":
            return "收款"
        case "3":
            return "付款"
        case "4":
            return "报销"
    }
}

/**
 * 返回审核状态文字信息
 * 
 */

export function reviewedStatusText(t) {
    t = t + ''
    switch (t) {
        case "0":
            return "未审核"
        case "1":
            return "审核已通过"
        case "2":
            return "审核拒绝"
        case "3":
            return "审核中"
        case "4":
            return "审核中途取消"
    }
}

/**
 * 项目审核状态
 */

export function projectStatusText(t) {
    t = t + ''
    switch (t) {
        case "0":
            return "审核中"
        case "1":
            return "审核已通过"
        case "2":
            return "审核拒绝"
    }
}

/**
 * 财务是否已结算
 */

export function settlementText(t) {
    t = t + ''
    switch (t) {
        case '0':
            return '财务未支付'
        case '1':
            return '财务已支付'
        case '2':
            return '财务拒绝支付'
    }
}


/**
 * 监听本地存储数据的变化
 */

export function dispatchEventStroage() {
    const signSetItem = localStorage.setItem
    localStorage.setItem = function (key, val) {
        let setEvent = new Event('setItemEvent')
        setEvent.key = key
        setEvent.newValue = val
        window.dispatchEvent(setEvent)
        signSetItem.apply(this, arguments)
    }
}

/**
 * YYMMDDHHMMSS 转化 MMDDHHMM
 */

export function timeChange(t, s) {
    if (!t) {
        return ''
    }
    console.log(t)
    const date = s ? new Date(t) : new Date((t.replace(/-/g, "/")));
    // 获取月、日、时、分
    const month = date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1); // 月份从0开始，需要加1
    const day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
    if (s) {
        const year = date.getFullYear()
        return `${year}-${month}-${day}`
    }
    const hours = date.getHours() > 9 ? date.getHours() : "0" + date.getHours();
    const minutes = date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()
    // 生成新的时间字符串
    return `${month}.${day} ${hours}:${minutes}`;

}

/**
 * 元间转换成万元位
 */

export function tenThousand(num, f) {
    let money = (num * 0.0001).toFixed(2)
    return f ? money : money + '万'
}

/**
 * 
 * 年份下拉统一
 */

export let yearOption = [
    { text: "全部", value: null },
    { text: "2020", value: 2020 },
    { text: "2021", value: 2021 },
    { text: "2022", value: 2022 },
    { text: "2023", value: 2023 },
    { text: "2024", value: 2024 },
    { text: "2025", value: 2025 },
    { text: "2026", value: 2026 },
]

