import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  component: Home,
  meta: {
    deepth: -1,
  }
},
{
  path: '/pjList',
  name: 'pjList',
  component: () => import('../views/pjList.vue'),
  meta: {
    deepth: 1,
    keepAlive: true,
  }
},
{
  path: '/newPj',
  name: 'newPj',
  component: () => import('../views/newPj.vue'),
  meta: {
    keepAlive: true,
    deepth: 2
  },
},
{
  path: '/pjDetails',
  name: 'pjDetails',
  component: () => import('../views/pjDetails'),
  meta: {
    keepAlive: true,
    deepth: 3
  }
},
{
  path: '/progressReport',
  name: 'progressReport',
  component: () => import('../views/progressReport.vue'),
  meta: {
    deepth: 1
  }
},
{
  path: '/newPjSchedule',
  name: 'newPjSchedule',
  component: () => import('../views/newPjSchedule'),

  meta: {
    keepAlive: true,
    deepth: 2
  },


},
{
  path: '/progressDetails',
  name: 'progressDetails',
  component: () => import('../views/progressDetails'),
  meta: {
    deepth: 4
  }
},
{
  path: '/pjCollection',
  name: 'pjCollection',
  component: () => import('../views/pjCollection.vue'),
  meta: {
    deepth: 1,
    keepAlive: true,
  }
},
{
  path: '/newPjCollection',
  name: 'newPjCollection',
  component: () => import('../views/newPjCollection.vue'),
  meta: {
    keepAlive: true,
    deepth: 2
  }


},
{
  path: '/collectionDetails',
  name: 'collectionDetails',
  component: () => import('../views/collectionDetails.vue'),
  meta: {
    deepth: 4
  }

},
{
  path: '/pjPayment',
  name: 'pjPayment',
  component: () => import('../views/pjPayment.vue'),
  meta: {
    keepAlive: true,
    deepth: 1,
  }
},
{
  path: '/newPjPayment',
  name: 'newPjPayment',
  component: () => import('../views/newPjPayment.vue'),
  meta: {
    keepAlive: true,
    deepth: 2
  },
},
{
  path: '/paymentDetails',
  name: 'paymentDetails',
  component: () => import('../views/paymentDetails.vue'),
  meta: {
    keepAlive: true,
    deepth: 4
  }
},
{
  path: '/pjReimbursement',
  name: 'pjReimbursement',
  component: () => import('../views/pjReimbursement.vue'),
  meta: {
    deepth: 1,
    keepAlive: true,
  }
},
{
  path: '/newPjReimbursement',
  name: 'newPjReimbursement',
  component: () => import('../views/newPjReimbursement.vue'),
  meta: {
    keepAlive: true,
    deepth: 2
  }

},
{
  path: '/reimbursementDetails',
  name: 'reimbursementDetails',
  component: () => import('../views/reimbursementDetails.vue'),
  meta: {
    keepAlive: true,
    deepth: 4
  }
},
{
  path: '/dataSummary',
  name: 'dataSummary',
  component: () => import('../views/dataSummary.vue'),
  meta: {
    deepth: 1
  }
},
{
  path: '/dataAnalysis',
  name: 'dataAnalysis',
  component: () => import('../views/dataAnalysis.vue'),
  meta: {
    deepth: 0,
    keepAlive: true
  }
},
{
  path: '/preview',
  name: 'preview',
  component: () => import('../views/preview.vue'),
  meta: {
    deepth: 5
  }
},
{
  path: '/process',
  name: 'process',
  component: () => import('../views/process.vue'),
  meta: {
    deepth: 5
  }
},
{
  path: '/auditList',
  name: 'auditList',
  component: () => import('../views/auditList'),
  meta: {
    deepth: 1,
    keepAlive: true,
  }
},
{
  path: '/auditDetails',
  name: 'auditDetails',
  component: () => import('../views/auditDetails'),
  meta: {
    deepth: 4
  }
},
{
  path: '/nonProject',
  name: 'nonProject',
  component: () => import('../views/nonProject'),
  meta: {
    deepth: 1,
    keepAlive: true,
  }
},
{
  path: '/transactionAmount',
  name: 'transactionAmount',
  component: () => import('../views/transactionAmount'),
  meta: {
    deepth: 1,
    keepAlive: true
  }
},
{
  path: '/transactionAmountDetails',
  name: 'transactionAmountDetails',
  component: () => import('../views/transactionAmountDetails'),
  meta: {
    deepth: 4,
    keepAlive: true
  }
},
{
  path: '/dataDetailsList',
  name: 'dataDetailsList',
  component: () => import('../views/dataDetailsList'),
  meta: {
    deepth: 1,
    keepAlive: true,
  }
},
{
  path: '/guaranteeList',
  name: 'guaranteeList',
  component: () => import('../views/guaranteeList'),
  meta: {
    deepth: 1
  }
},
{
  path: '/marginIncome',
  name: 'marginIncome',
  component: () => import('../views/marginIncome'),
  meta: {
    deepth: 1,
    keepAlive: true
  }
},
{
  path: '/depositExpenditure',
  name: 'depositExpenditure',
  component: () => import('../views/depositExpenditure'),
  meta: {
    deepth: 1,
    keepAlive: true
  }
},
{
  path: '/supplierList',
  name: 'supplierList',
  component: () => import('../views/supplierList'),
  meta: {
    deepth: 1,
    keepAlive: true
  }
},
{
  path: '/supplierDetails',
  name: 'supplierDetails',
  component: () => import('../views/supplierDetails'),
  meta: {
    deepth: 4,
    keepAlive: true
  }
},
{
  path: '/configList',
  name: 'configList',
  component: () => import('../views/configList'),
  meta: {
    deepth: 1,
  }
},
{
  path: '/configDetail',
  name: 'configDetail',
  component: () => import('../views/configDetail'),
  meta: {
    deepth: 4,
    keepAlive: true
  }
},
{
  path: '/configDetailList',
  name: 'configDetailList',
  component: () => import('../views/configDetailList'),
  meta: {
    deepth: 4,
  }
},
{
  path: '/fileImport',
  name: 'fileImport',
  component: () => import('../views/fileImport'),
  meta: {
    deepth: 2,
    // keepAlive: true,
  }
},
{
  path: '/404',
  name: 'noFound',
  component: () => import('../views/NoFound'),
},
{
  path: '*',
  redirect: '/404',
  hidden: true

}
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router