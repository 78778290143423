import request from "./request";


//获取配置 1项目审核人员配置 2项目类型 3项目报销类型 4项目付款类型 5项目报销明细类型 6特殊人员配置 （行政人员列表 财务人员列表 总经理人员列表） 9非项目类型 11抄送人配置
export function getConfig(data) {
    return request({
        url: `/project/configDetail/list?configId=${data}`,
        method: 'get'
    })

}

//获取登录权限
export function getUserInfo(code) {
    return request({
        url: '/oa/DingDingController/getuserInfo?code=' + code,
        method: 'get'
    })

}

//上传文件接口
export function fileUpload(data) {
    return request({
        url: '/project/file/uploadOss',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}

//获取文件接口
export function getFile(fileUrl) {
    return request({
        url: '/project/file/downloadRemoteproject/file/downloadRemote?link=' + fileUrl,
        method: 'post'
    })
}

//供应商列表
export function suppliserList() {
    return request({
        url: '/project/supplier/list',
        method: 'get'
    })
}

//总经理专属添加项目预算功能
export function additionalBudget(id, additionalBudget) {
    return request({
        url: `/project/info/${id}/${additionalBudget}`,
        method: 'get',
    })

}

//获取所有用户列表
export function getAllUserList() {
    return request({
        url: '/project/user/list',
        method: 'get'
    })
}

//获得统计数据显示版数据
export function statisticsPanel(year, belong, month) {
    return request({
        url: `/project/info/getStatisticsPanel/${year}/${belong}/${month}`,
        method: 'get',
    })
}

//获取统计信息的列表 数据看板
export function statisticlist(params) {
    return request({
        url: '/project/info/statisticlist',
        method: 'get',
        params
    })
}

//导出数据面板里面详细数据
export function statisticlistExport(data) {
    return request({
        url: '/project/info/statisticlist/export',
        method: 'post',
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data
    })
}

//获取收入保证金 数据看板
export function income(params) {
    return request({
        url: '/project/gathering/guaranteelist',
        method: 'get',
        params
    })
}

//收入保证金导出
export function incomeExport(data) {
    return request({
        url: '/project/gathering/guaranteelist/export',
        method: 'post',
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data
    })
}

//获取支出保证金 数据看板
export function expenditure(params) {
    return request({
        url: '/project/payment/guaranteelist',
        method: 'get',
        params
    })
}

export function expenditureExport(data) {
    return request({
        url: '/project/payment/guaranteelist/export',
        method: 'post',
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data
    })
}

export function getFiles(data) {
    return request({
        url: '/project/file/common/download/resource?resource=' + data,
        method: 'get',
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}