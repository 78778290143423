<template>
    <div>
        <div class="headTitle" v-if="equipment">
            <div class="title">{{ title }}</div>
            <div style="width: 10px; height: 16px;padding: 10px;" @click="goBack">
                <img class="backBtn" src="../assets/icon/fh_icon.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '项目管理'
        },
        routerPath: {
            type: String,
            default: '/'
        },
        isHome: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            equipment: false
        }
    },
    watch: {
        title: {
            handler(newly) {
                document.title = newly
            }
        }
    },
    created() {
        this.isMobile()
        document.title = this.title
    },
    methods: {
        goBack() {
            if (this.isHome) {
                return
            }
            this.$router.push({ path: `${this.routerPath}` })
        },
        isMobile() {
            this.equipment = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? false : true
        }
    }
}
</script>

<style scoped>
.headTitle {
    width: 100vw;
    height: 7vh;
    line-height: 7vh;
    font-size: 16px;
    font-weight: 600;
    background: #fff;
    position: relative;
}

.backBtn {
    width: 10px;
    height: 16px;
    position: absolute;
    top: 0;
    left: 3vw;
    bottom: 0;
    margin: auto;
}

.title {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}
</style>