import Vue from 'vue'
import App from './App.vue'
import router from './router'
//在main.js中引入
import '@vant/touch-emulator';

import { dispatchEventStroage } from './units/units'
import downloadHtmlToPdf from './units/downloadHtmlToPdf'

Vue.config.productionTip = false
Vue.use(dispatchEventStroage)
Vue.use(downloadHtmlToPdf)

new Vue({
  router,
  render: h => h(App),
  beforeCreate() { //带数据代理没有完成
    Vue.prototype.$bus = this //安装全局事件总线，$bus就是当前的vm实例
  }

}).$mount('#app')