import request from "./request";

//新增项目信息
export function infoAdd(data) {
    return request({
        url: '/project/info',
        method: 'post',
        data
    })
}

//修改项目信息
export function infoPut(data) {
    return request({
        url: '/project/info',
        method: 'put',
        data
    })
}

//获取项目信息  
export function getInfo(id) {
    return request({
        url: '/project/info/' + id,
        method: 'get'
    })
}

//获取首页数据或者更多数据
export function getProjectStatistics(year) {
    return request({
        url: '/project/info/getProjectStatistics/' + year,
        method: 'get'
    })
}

//删除项目信息
export function infoDeltel(ids) {
    return request({
        url: '/project/info/' + ids,
        method: 'delete'
    })
}

//导出项目信息
export function infoExport() {
    return request({
        url: '/project/info/export',
        method: 'post'
    })
}

//导入项目信息
export function infoImportData(data) {
    return request({
        url: '/project/info/importData',
        method: 'post',
        data
    })
}

//获取项目信息列表
export function infoList(query) {
    return request({
        url: '/project/info/list',
        method: 'get',
        params: query
    })
}

//更新项目权限
export function accessPowerPut(ids, pjId) {
    return request({
        url: `/project/info/accessPower/${ids}/${pjId}`,
        method: 'put'
    })
}

//获取项目访问权限
export function getAccessPower(pjId) {
    return request({
        url: `/project/info/getAccessPower/${pjId}`,
        method: 'get'
    })
}
