<template>
  <div id="app">
    <keep-alive :include="includeArr.length == 0 ? ' ' : includeArr.join(',')">
      <router-view v-if="$route.meta.keepAlive" class="router-view">
      </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" class="router-view"></router-view>
  </div>
</template>

<script>
import Vue from 'vue';
import { Tabbar, TabbarItem } from 'vant';
import { GetCode } from './units/dd'
// import { setToken, getToken } from './units/auth'
// import { getConfig } from './api/api'

Vue.use(Tabbar);
Vue.use(TabbarItem);

export default {
  data() {
    return {
      includeArr: []
    };
  },
  watch: {
    $route(to, from) {
      console.log('to.meta.keepAlive', to.meta.keepAlive)
      // 如果 要 to(进入) 的页面是需要 keepAlive 缓存的，把 name push 进 include数组
      if (to.meta.keepAlive) {
        !this.includeArr.includes(to.name) && this.includeArr.push(to.name)
        console.log('存储', this.includeArr)
      }
      // 如果 要 form(离开) 的页面是 keepAlive缓存的，再根据 deepth 来判断是前进还是后退，如果是后退，需要清掉缓存的组件。
      if (from.meta.keepAlive && to.meta.deepth < from.meta.deepth) {
        var index = this.includeArr.indexOf(from.name)
        index !== -1 && this.includeArr.splice(index, 1)
      }
    }
  },
  created() {
    this.getGetCode()
  },
  methods: {
    async getGetCode() {
      await GetCode()
    }
  }
}
</script>


<style lang="less" scoped>
// html,
// body {
//   margin: 0;
//   padding: 0;
//   overflow: -moz-hidden-unscrollable;
//   height: 100%
// }

// /* 隐藏滚动条 */
// body::-webkit-scrollbar {
//   display: none;
// }

// body {
//   -ms-overflow-style: none;
//   height: 100%;
//   width: calc(100vw + 18px);
//   overflow: auto;
// }

#app {
  // width: 100vw;
  // height: 100vh;
  // margin: 0;
  // padding: 0;
  // overflow: hidden;
  // overflow-x: scroll;
  // /* 设置溢出滚动 */
  // white-space: nowrap;
  // overflow-y: hidden;
  // /* 隐藏滚动条 */
  // scrollbar-width: none;
  // /* firefox */
  // -ms-overflow-style: none;
  /* IE 10+ */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/deep/ .van-dropdown-menu__bar {
  box-shadow: none;
  height: unset;
}

/deep/ .van-dropdown-item__option--active,
/deep/ .van-dropdown-menu__title--active,
/deep/ .van-dropdown-item__icon {
  color: #0092fe !important;
}
</style>
