
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
export default {
    install(Vue) {
        Vue.prototype.downloadHtmlToPdf = function () {
            var title = this.htmlTitle
            html2Canvas(document.querySelector('#printer'), {    // 这个id很重要，一旦填写错了或者不填写就会报错
                allowTaint: true,
                useCORS: true,//解决跨域
                scale: 2,//解决模糊
                // height: screen.availHeight //解决下方白边问题
            }).then(function (canvas) {

                let contentWidth = canvas.width
                let contentHeight = canvas.height
                let pageHeight = contentWidth / 592.28 * 841.89
                let leftHeight = contentHeight
                let position = 0
                let imgWidth = 595.28
                let imgHeight = 592.28 / contentWidth * contentHeight
                // let contentWidth = canvas.style.width.replace('px', '')
                // let contentHeight = canvas.style.height.replace('px', '')
                // // let contentWidth = 3492
                // // let contentHeight = 2080
                // console.log('画图内容', canvas, contentWidth, contentHeight, screen)
                // // let pageHeight = contentWidth / 592.28 * 841.89
                // let pageHeight = contentWidth
                // let leftHeight = contentHeight 
                // let position = 0
                // let imgWidth = 595.28
                // let imgHeight = contentHeight / contentWidth * imgWidth

                let pageData = canvas.toDataURL('image/jpeg', 1.0)
                let PDF = new JsPDF('', 'pt', 'a4')
                if (leftHeight < pageHeight) {
                    PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
                } else {
                    while (leftHeight > 0) {
                        PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                        leftHeight -= pageHeight
                        position -= 841.89
                        if (leftHeight > 0) {
                            PDF.addPage()
                        }
                    }
                }
                PDF.save(title + '.pdf')
            }
            )
        }
    }
}

