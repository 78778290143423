import axios from 'axios'
import {
  tansParams
} from '../units/units'
import {
  getToken
} from '../units/auth'

import { Toast } from 'vant'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const request = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分

  //  baseURL: 'http://192.168.1.50:8080/',
  //本地
  // baseURL: 'http://192.168.1.41:8888/',
  //正式环境q
  // baseURL: 'http://47.106.121.82:8888/',
  baseURL: 'https://adminapi.prod.gdzcit.com/',
  //测试环境
  // baseURL: 'http://120.76.250.252:8888/',
  // 超时
  timeout: 60000
})

// request拦截器
request.interceptors.request.use(config => {

  // 是否需要设置 token

  const isToken = (config.headers || {}).isToken === false
  if (getToken('userId') && !isToken) {
    config.headers['Authorization'] = getToken('userId') // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  if (config.method === 'get' && config.method === 'put' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config
}, error => {
  Promise.reject(error)
})

request.interceptors.response.use(res => {
  const code = res.data.code || 200;
  // 获取错误信息

  let msg = res.data.msg
  if (code == 200) {
    return res
  } else {
    if (msg == null) {
      msg = "数据异常，请重新操作"
    }
    Toast.fail(msg)
    return Promise.reject(new Error(msg))
  }
}, error => {
  // 根据传回来的错误信息`erroe`进行判断 意为：`error.code`
  if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
    Toast.fail('请求超时，请检查网络状态是否正常')
  }
  // 若不是超时,则返回未错误信息
  return Promise.reject(error)
}
)




export default request