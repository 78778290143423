import * as dd from 'dingtalk-jsapi'
import { getUserInfo, getConfig } from '../api/api'
import { setToken } from './auth';
import { Notify } from 'vant';



// const corpId = 'dinga63f379a56c6fdd8acaaa37764f94726' // 中创钉钉企业id
const corpId = 'ding60938e66e6ea3964a1320dcb25e91351'//大美钉钉企业id
const base = corpId == 'dinga63f379a56c6fdd8acaaa37764f94726' ? 'http://projectmanager.gdzcit.com/#' : 'https://damei.projectmanager.gdzcit.com/#'
/* eslint-disable */
export function GetCode() {

    dd.ready(() => {
        dd.runtime.permission.requestAuthCode({
            corpId: corpId,
            onSuccess: (info) => {
                getUserInfo(info.code).then(res => {
                    if (res.data.code == 200) {
                        setToken('userId', res.data.data.result.userid)
                        setToken('avatar', res.data.data.result.avatar)
                        setToken('userName', res.data.data.result.name)
                        getConfig(6).then(response => {
                            let powerList = []
                            response.data.rows.forEach(it => {
                                if (it.commonValue == res.data.data.result.userid) {
                                    powerList.push(it.value)
                                }
                            })
                            setToken('powerList', JSON.stringify(powerList))
                        })
                    } else {
                        Notify('登录失败，请检查当前网络状态')
                        this.$router.push({ path: '/404' })
                    }
                })
            },
            onFail: (err) => {
                alert('fail')
                alert(JSON.stringify(err))
                this.$router.push({ path: '/404' })
            }
        })
    })
}

export function ddShare(url, content) {
    if ("" + dd.env.platform !== "notInDingTalk") {
        dd.ready(function () {
            dd.biz.navigation.setRight({
                show: true, //控制按钮显示， true 显示， false 隐藏， 默认true
                control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
                // text: "...", //控制显示文本，空字符串表示显示默认文本
                onSuccess: function (result) {
                    console.log('result', result)
                    //如果control为true，则onSuccess将在发生按钮点击事件被回调
                    dd.biz.util.share({
                        type: 0, //分享类型，0:全部组件 默认；1:只能分享到钉钉；2:不能分享，只有刷新按钮
                        url: base + url,
                        title: '项目管理系统',
                        content: content,
                        // image: "../assets/icon/shareIcon.jpg",
                        onSuccess: function () {
                            //onSuccess将在调起分享组件成功之后回调
                            /**/
                        },
                        onFail: function (err) {
                            console.log('err', err)
                            Notify('当前页面没有权限')
                            this.$router.push({ path: '/404' })

                        }
                    });
                },
                onFail: function (err) {
                    console.log('err1', err)
                    Notify('登录失败，请检查当前网络状态')
                    this.$router.push({ path: '/404' })
                }
            });
        });
    }
}



