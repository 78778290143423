import request from "./request";

//新增配置明细
export function configDetailAdd(data) {
    return request({
        url: '/project/configDetail',
        method: 'post',
        data
    })
}

//修改配置明细
export function configDetailPut(data) {
    return request({
        url: '/project/configDetail',
        method: 'put',
        data
    })
}

//获取配置明细 
export function getConfigDetail(id) {
    return request({
        url: '/project/configDetail/' + id,
        method: 'get'
    })
}

//删除配置明细
export function configDetailDeltel(ids) {
    return request({
        url: '/project/configDetail/' + ids,
        method: 'delete'
    })
}

//导出配置明细
export function configDetailExport() {
    return request({
        url: '/project/configDetail/export',
        method: 'post'
    })
}

//导入配置明细 
export function configDetailImportData(data) {
    return request({
        url: '/project/configDetail/importData',
        method: 'post',
        data
    })
}

//获取配置明细列表 1 项目审核人员配置 2 项目类型 3 项目报销类型 4项目付款类型 5 项目报销明细类型
export function configDetailList(id) {
    return request({
        url: '/project/configDetail/list?configId='+id,
    })
}
